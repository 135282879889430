






import { Component, Vue } from 'vue-property-decorator';

import { alertModule } from '@/store/viewModule/common/alertModule.ts';

@Component({
  components: {
  },
})
export default class AlertContainer extends Vue {
  public get alert() {
    return alertModule.alerts.length > 0
      ? alertModule.alerts[alertModule.alerts.length - 1]
      : null;
  }
}
