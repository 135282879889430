





















































































































































































































import Vue from 'vue';
// models
import { RemonadeTimezone, Timezone, timezones } from '@/service/Timezones';
// conmonents
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { TagResponse } from 'remonade-api/lib';
import { commonModule } from '@/store/viewModule/common/commonModule';

export interface DataType {
  createTagModal: boolean;
  deleteTagModal: boolean;
  creatingTagName: string;
  deletingTag: TagResponse | null;
}

export default Vue.extend({
  name: 'TeamTagSettingContainer',

  data() {
    return {
      createTagModal: false as boolean,
      deleteTagModal: false as boolean,
      creatingTagName: '' as string,
      deletingTag: null as TagResponse | null,
    };
  },

  components: {
  },

  computed: {
    usingTags(): TagResponse[] {
      return settingViewModule.usingTags;
    },
    archivedTags(): TagResponse[] {
      return settingViewModule.archivedTags;
    },
  },

  methods: {
    openDeleteModal(tag: TagResponse) {
      this.deletingTag = tag;
      this.deleteTagModal = true;
    },
    async createTag() {
      commonModule.setLoader(true);
      await settingViewModule.createTag(this.creatingTagName);
      commonModule.setLoader(false);
      this.createTagModal = false;
    },
    async updateTag({ tag, key, value }) {
      const newTag = {...tag};
      if (key === 'name') {
        newTag.tagName = value;
      } else if (key === 'color') {
        newTag.color = value;
      }
      commonModule.setLoader(true);
      await settingViewModule.updateTag(newTag);
      commonModule.setLoader(false);
    },
    async deleteTag() {
      if (this.deletingTag) {
        commonModule.setLoader(true);
        await settingViewModule.deleteTag(this.deletingTag);
        commonModule.setLoader(false);
        this.deleteTagModal = false;
        this.deletingTag = null;
      }
    },
    async updateTagArchive(tag: TagResponse) {
      await settingViewModule.updateTagArchive(tag);
    },
  },

  watch: {
    createTagModal() {
      if (!this.createTagModal) {
        this.creatingTagName = '';
      }
    },
    deleteTagModal() {
      if (!this.deleteTagModal) {
        this.deletingTag = null;
      }
    },
  },
});
