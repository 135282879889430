





































import Vue from 'vue';
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { UserResponse } from 'remonade-api/lib';

export default Vue.extend({
  name: 'SettingChannelsContainer',

  components: {
  },

  computed: {
    selectedChannel() {
      return settingViewModule.selectedChannel;
    },
    user(): UserResponse | null {
      return settingViewModule.user;
    },
    isAdmin(): boolean {
      if (this.user && this.user.userType === 'admin') {
        return true;
      }
      return false;
    },
  },

  methods: {
    selectChannel(channel: string) {
      settingViewModule.selectChannel(channel);

      // for mobile
      this.$emit('selectChannel');
    },
  },

  async mounted() {
  },
});
