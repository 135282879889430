import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';

import store from '@/store/index';

type alertType = 'base' | 'success' | 'warning' | 'danger';
interface Alert {
  message: string;
  type: alertType;
}

export interface AlertState {
  alerts: Alert[];
}

@Module({ dynamic: true, store, name: 'alert', namespaced: true})
class AlertModule extends VuexModule implements AlertState {
  public alerts: Alert[] = [];

  @Action
  public showAlert(alert: Alert) {
    this.pushAlert(alert);
    window.setTimeout(this.popAlert, 2500);
  }

  @Mutation
  public pushAlert(alert: Alert) {
    this.alerts.push(alert);
  }

  @Mutation
  public popAlert() {
    this.alerts.shift();
  }

  @Mutation
  public clearAlert() {
    this.alerts = [];
  }
}

export const alertModule = getModule(AlertModule);
