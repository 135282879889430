


















































































































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
// models
import { RemonadeLanguage, languages } from '@/service/Languages';
import { timezones, Timezone, RemonadeTimezone } from '@/service/Timezones';
// conmonents
import S3Image from '@/components/atoms/S3Image.vue';
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { GoogleCalendarResponse, UserResponse } from 'remonade-api/lib';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { isMobile } from '@/service/StyleService';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import LocalDataService from '@/service/LocalDataServices';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';

export interface DataType {
  dialog: boolean;
  options: { format: string, circle: boolean};
}

export default Vue.extend({
  name: 'UserSettingContainer',

  data(): DataType {
    return {
      dialog: false as boolean,
      options: { format: 'jpeg', circle: false },
    };
  },

  components: {
    S3Image,
  },

  computed: {
    user(): UserResponse | null {
      return settingViewModule.user;
    },
    isGoogleUser(): boolean {
      return settingViewModule.isGoogleUser;
    },
    timezones(): RemonadeTimezone[] {
      const zones = timezones as Timezone[];
      return zones.map((t) => {
        return {
          label: t.text,
          offset: t.offset,
        };
      });
    },
    languages(): RemonadeLanguage[] {
      const langs = languages as RemonadeLanguage[];
      return langs.map((lang) => {
        return {
          label: lang.label,
          locale: lang.locale,
        };
      });
    },
    calendars(): GoogleCalendarResponse[] {
      return settingViewModule.calendars;
    },
    googleCalendarConnectionSetting: {
      get(): boolean {
        if (settingViewModule.user) {
          return settingViewModule.user.isGoogleCalendarEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        if (value) {
          const calendars = await settingViewModule.getGoogleCalendars();
          if ('error' in calendars) {
            await settingViewModule.authorizeGoogleCalendar();
          }
        }
        await settingViewModule.updateUser({ key: 'isGoogleCalendarEnabled', value });
        commonModule.setLoader(false);
      },
    },
    userEmailNotification: {
      get(): boolean {
        return settingViewModule.userEmailNotification;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updateUser({ key: 'isEmailEnabled', value });
        commonModule.setLoader(false);
      },
     },
    userPushNotificationChat: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'chat');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'chat', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationTaskIncomplete: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'task_incomplete');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'task_incomplete', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationTaskNoRegist: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'task_no_regist');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'task_no_regist', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationMemberActive: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'member_active');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'member_active', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationMemberTaskDone: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'member_task_done');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'member_task_done', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationMemberJournalCreate: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'member_journal_create');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'member_journal_create', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationChannelUserAdd: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'channel_user_add');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'channel_user_add', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationSuggestPool: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'suggest_pool');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'suggest_pool', value });
        commonModule.setLoader(false);
      },
    },
    userPushNotificationTakePool: {
      get(): boolean {
        const find = settingViewModule.user.notificationSetting.find((setting) => setting.notificationType === 'take_pool');
        if (find) {
          return find.isEnabled;
        }
        return false;
      },
      async set(value) {
        commonModule.setLoader(true);
        await settingViewModule.updatePushNotificationEnabled({ key: 'take_pool', value });
        commonModule.setLoader(false);
      },
    },
    isMobile() {
      return isMobile(window);
    },
  },

  methods: {
    selectImage() {
      const inputElement = this.$refs.uploadInput as HTMLInputElement;
      if (inputElement) {
        inputElement.click();
        this.dialog = true;
      }
    },
    imageSelected(event: Event) {
      const target: HTMLInputElement | null = event.target as HTMLInputElement;
      if (target && target.files) {
        const files = target.files;
        if (files.length > 0) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target) {
              (this.$refs.croppieRef as any).bind({
                url: (e.target as any).result,
              });
            }
          };
          reader.readAsDataURL(files[0]);
        }
      }
    },
    async updateUser({ key, value }) {
      commonModule.setLoader(true);
      await settingViewModule.updateUser({ key, value });
      commonModule.setLoader(false);
    },
    async crop() {
      (this.$refs.croppieRef as any).result(this.options, async (image) => {
        await settingViewModule.updateUser({ key: 'snapIconUrl', value: image });
      });
      this.dialog = false;
    },
    calendarConnected(id: string) {
      if (settingViewModule.user && settingViewModule.user.googleCalendarIds) {
        if (settingViewModule.user.googleCalendarIds.includes(id)) {
          return true;
        }
      }
      return false;
    },
    async updateCalendarConnectionEnabled(id: string) {
      if (settingViewModule.user) {
        const user = { ...settingViewModule.user };
        if (user.googleCalendarIds !== null && user.googleCalendarIds !== undefined) {
          if (user.googleCalendarIds.includes(id)) {
            user.googleCalendarIds = user.googleCalendarIds.filter((calendarId) => calendarId !== id);
          } else {
            user.googleCalendarIds.push(id);
          }
        } else {
          user.googleCalendarIds = [];
          user.googleCalendarIds.push(id);
        }
        await this.updateUser({ key: 'googleCalendarIds', value: user.googleCalendarIds});

        taskDialogModule.getConnectCalendars();
      }
    },
    logout() {
      commonModule.logout();
    },
  },
});
