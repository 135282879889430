import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { userModule } from '@/store/dataModule/user/userModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { chatModule } from '@/store/dataModule/chat/chatModule';
import dayjs from 'dayjs';
import { getChatReactionsWithTodayUser, getTodayUser } from '@/service/ui/ModelService';
import LocalDataServices from '@/service/LocalDataServices';
import { ChatReaction, TodayUser } from '@/model/types';
import { reportsModule } from '@/store/dataModule/report/reportsModule';
import { getDoneRateChartData } from '@/service/ui/StatsService';
import { snapshotModule } from '@/store/dataModule/snapshot/snapshotModule';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';


export interface MobileChatViewState {
  selectedChannelId: string;
}

@Module({ dynamic: true, store, name: 'mobile-chat-view', namespaced: true })
class MobileChatViewModule extends VuexModule implements MobileChatViewState {
  public selectedChannelId: string = 'ALL';

  // for stats summary in user card
  public endDate = dayjs().format('YYYY-MM-DD');
  public startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');

  public get me() {
    if (userModule.user) {
      const id = userModule.user.userId;
      // ここ
      const tasks = reportsModule.reports.filter((report) => report.userId === id);
      const data = getDoneRateChartData(tasks, this.startDate, this.endDate);
      const snap = snapshotModule.snapshots.find((s) => s.userId === `${userModule.user!.teamId}#${userModule.user!.userId}`) || null;
      const presence = pusherModule.presenceUsers.includes(userModule.user.userId);
      return getTodayUser(userModule.user, presence, reportsModule.todayReports, snap ? snap.originalUrl : null, data);
    }
    return null;
  }

  public get selectedChannel() {
    if (this.selectedChannelId !== 'ALL') {
      const selectedChannel = channelsModule.channels.find((channel) => channel.channelId === this.selectedChannelId);
      return selectedChannel || null;
    }
    return null;
  }

  public get selectedChannelUsers() {
    if (this.selectedChannel) {
      return this.teamUsers.filter((u) => this.selectedChannel!.users.find((channelUser) => channelUser.userId === u.userId) ? true : false);
    }
    return this.teamUsers;
  }

  public get teamUsers(): TodayUser[] {
    if (userModule.user) {
      return userModule.teamUsers
        .filter((tu) => tu.userId !== userModule.user!.userId)
        .map((u) => {
          const reports = reportsModule.reports.filter((report) => (report.userId === u.userId) && (report.type === 'task'));
          const data = getDoneRateChartData(reports, this.startDate, this.endDate);
          const snap = snapshotModule.snapshots.find((s) => s.userId === `${u.teamId}#${u.userId}`) || null;
          const presence = pusherModule.presenceUsers.includes(u.userId);
          return getTodayUser(u, presence, reportsModule.todayReports.filter((report) => report.type === 'task'), snap ? snap.originalUrl : null, data);
        });
    }
    return [];
  }

  /**
   * Getters: Chat
   */
  public get chats() {
    const chats = chatModule.chats;
    if (this.selectedChannelId !== 'ALL') {
      return chats
              .filter((c) => c.channelId === this.selectedChannelId)
              .sort((c1, c2) => {
                const createdAt1 = dayjs(c1.createdAt);
                const createdAt2 = dayjs(c2.createdAt);
                if (createdAt1 > createdAt2) {
                  return -1;
                }
                if (createdAt1 < createdAt2) {
                  return 1;
                }
                return 0;
              });
    }
    return chats
            .filter((c) => !c.channelId)
            .sort((c1, c2) => {
              const createdAt1 = dayjs(c1.createdAt);
              const createdAt2 = dayjs(c2.createdAt);
              if (createdAt1 > createdAt2) {
                return -1;
              }
              if (createdAt1 < createdAt2) {
                return 1;
              }
              return 0;
            });
  }

  public get chatsWithReactions() {
    return this.chats.map((chat) => {
      let sender = this.teamUsers.find((user) => user.userId === chat.userId) || null;

      if (this.me && !sender && chat.userId === this.me.userId) {
        sender = this.me;
      }

      if (chat.reactions) {
        const reactionForView = getChatReactionsWithTodayUser(chat.reactions, [...this.teamUsers, this.me!], this.me!).sort((reaction1, reaction2) => {
          if (reaction1.reaction > reaction2.reaction) { return 1; }
          if (reaction1.reaction < reaction2.reaction) { return -1; }
          return 0;
        });

        return {
          chat,
          user: sender,
          reactions: reactionForView,
        };
      }

      return {
        chat,
        user: sender,
        reactions: [],
      };
    });
  }

  /**
   * Init
   */
  @Action
  public async getChatData() { // fetch all data that today grid need
    if (userModule.user) {
      chatModule.listChat();
      channelsModule.listChannels();
    }
  }


  /**
   * Channels
   */
  @Action
  public async listChannels() {
    const user = LocalDataServices.getUser();
    if (user) {
      await channelsModule.listChannels();
    }
  }

  @Mutation
  public selectChannel(channelId: string) {
    this.selectedChannelId = channelId;
  }

  /**
   * Chat
   */
  @Action
  public async sendChat(message: string) {
    if (userModule.user) {
      await chatModule.createChat({
        teamId: userModule.user.teamId,
        comment: message,
        channelId: this.selectedChannelId === 'ALL' ? null : this.selectedChannelId,
      });
    }
  }

  @Action
  public async clickReaction({ reaction, chatId }: { reaction: ChatReaction, chatId: string }) {
    if (userModule.user) {
      if (reaction.class && reaction.class === 'mine') {
        // delete
        await chatModule.deleteChatReaction({
          teamId: userModule.user.teamId,
          reaction: reaction.reaction,
          chatId,
        });
      } else {
        // add
        await chatModule.createChatReaction({
          teamId: userModule.user.teamId,
          reaction: reaction.reaction,
          chatId,
        });
      }
    }
  }

  @Action
  public async addReaction({ emoji, chatId }: { emoji: string, chatId: string }) {
    if (userModule.user) {
      await chatModule.createChatReaction({
        teamId: userModule.user.teamId,
        reaction: emoji,
        chatId,
      });
    }
  }
}

export const mobileChatViewModule = getModule(MobileChatViewModule);
