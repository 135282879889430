




























































































import Vue from 'vue';
// models
import { RemonadeTimezone, Timezone, timezones } from '@/service/Timezones';
// conmonents
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { teamNotificationModule } from '@/store/dataModule/notification/teamNotificationModule';
import { TeamResponse } from 'remonade-api/lib';
import { commonModule } from '@/store/viewModule/common/commonModule';

export interface DataType {
}

export default Vue.extend({
  name: 'TeamTeamSettingContainer',

  data() {
    return {
    };
  },

  components: {
  },

  computed: {
    team(): TeamResponse | null {
      return settingViewModule.team;
    },
    timezones(): RemonadeTimezone[] {
      const zones = timezones as Timezone[];
      return zones.map((t) => {
        return {
          label: t.text,
          offset: t.offset,
        };
      });
    },
    bit(): number {
      return settingViewModule.bit;
    },
    teamTimezone: {
      get(): Timezone | null {
        if (this.team && this.team.timezone) {
          return JSON.parse(this.team.timezone);
        }
        return null;
      },
      set(value) {
        this.updateTeam({ key: 'timezone', value });
      },
    },
    teamMon: {
      get(): boolean {
        return this.getWeekdayValue('Mon');
      },
      set() {
        this.updateTeam({ key: 'Mon', value: 0b1 });
      },
    },
    teamTue: {
      get(): boolean {
        return this.getWeekdayValue('Tue');
      },
      set() {
        this.updateTeam({ key: 'Tue', value: 0b10 });
      },
    },
    teamWed: {
      get(): boolean {
        return this.getWeekdayValue('Wed');
      },
      set() {
        this.updateTeam({ key: 'Wed', value: 0b100 });
      },
    },
    teamThu: {
      get(): boolean {
        return this.getWeekdayValue('Thu');
      },
      set() {
        this.updateTeam({ key: 'Thu', value: 0b1000 });
      },
    },
    teamFri: {
      get(): boolean {
        return this.getWeekdayValue('Fri');
      },
      set() {
        this.updateTeam({ key: 'Fri', value: 0b10000 });
      },
    },
    teamSat: {
      get(): boolean {
        return this.getWeekdayValue('Sat');
      },
      set() {
        this.updateTeam({ key: 'Sat', value: 0b100000 });
      },
    },
    teamSun: {
      get(): boolean {
        return this.getWeekdayValue('Sun');
      },
      set() {
        this.updateTeam({ key: 'Sun', value: 0b1000000 });
      },
    },
  },

  methods: {
    getWeekdayValue(day): boolean {
      switch (day) {
        case 'Mon':
          return (this.bit & 0b1) === 1;
          break;
        case 'Tue':
          return (this.bit & 0b10) === 0b10;
          break;
        case 'Wed':
          return (this.bit & 0b100) === 0b100;
          break;
        case 'Thu':
          return (this.bit & 0b1000) === 0b1000;
          break;
        case 'Fri':
          return (this.bit & 0b10000) === 0b10000;
          break;
        case 'Sat':
          return (this.bit & 0b100000) === 0b100000;
          break;
        case 'Sun':
          return (this.bit & 0b1000000) === 0b1000000;
          break;
        default:
          return false;
          break;
      }
    },
    async updateTeam({ key, value }) {
      commonModule.setLoader(true);
      await settingViewModule.updateTeam({ key, value });
      commonModule.setLoader(false);
    },
  },
});
