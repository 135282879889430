


















































































































































import Vue from 'vue';
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { alertModule } from '@/store/viewModule/common/alertModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { PaymentResponse, PlanResponse, TeamResponse } from 'remonade-api/lib';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { getCheckoutSession } from '@/api/client';
import { userModule } from '@/store/dataModule/user/userModule';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
  stripe: stripe.Stripe | null;
  currency: string;
}

export default Vue.extend({
  name: 'TeamPlanSettingContainer',

  data(): DataType {
    return {
      stripe: null as stripe.Stripe | null,
      currency: 'USD',
    };
  },

  components: {
  },

  computed: {
    team(): TeamResponse | null {
      return settingViewModule.team;
    },
    plan(): PlanResponse | null {
      if (settingViewModule.team) {
        return settingViewModule.team.plan;
      }
      return null;
    },
    canDonwgrade() {
      if (userModule.teamUsers) {
        return userModule.teamUsers.length <= 5;
      }
      return false;
    },
    isFreePlan(): boolean {
      if (this.plan) {
        return this.plan.price === 0;
      }
      return false;
    },
    isPaymentRegistered(): boolean {
      if (settingViewModule.team && settingViewModule.team.payment) {
        return settingViewModule.team.payment.isPaymentRegistered;
      }
      return false;
    },
    isTrial(): boolean {
      if (this.team && this.team.payment && this.team.payment.isTrial) {
        return true;
      } else {
        return false;
      }
    },
    planList(): PlanResponse[] {
      return settingViewModule.planList;
    },
    v2PlanList(): PlanResponse[] {
      return planModule.v2PlanList;
    },
    planListToShow(): PlanResponse[] {
      return this.v2PlanList.filter((p) => p.currency === this.currency);
    },
    currentPlanName(): string {
      if (this.plan) {
        if (this.isFreePlan) {
          return this.$t('freePlan');
        } else {
          return this.plan.name;
        }
      }
      return 'here';
    },
  },

  methods: {
    async checkoutToRegister(planId: number) {
      commonModule.setLoader(true);
      const sessionResponse = await getCheckoutSession({
        teamId: this.team.teamId,
        type: 'payment',
        planId,
        currency: this.currency,
      });
      commonModule.setLoader(false);
      const stripeKey = process.env.VUE_APP_STRIPE_KEY || '';
      const stripe = Stripe(stripeKey) as stripe.Stripe;
      if (sessionResponse.sessionId && stripe) {
        stripe.redirectToCheckout({
          sessionId: sessionResponse.sessionId,
        });
      }
    },
    async checkoutToEdit(planId: number) {
      commonModule.setLoader(true);
      const sessionResponse = await getCheckoutSession({
        teamId: this.team.teamId,
        type: 'customer',
        planId,
        currency: this.currency,
      });
      commonModule.setLoader(false);
      if (sessionResponse.url) {
        window.open(sessionResponse.url, '_blank');
      }
    },
  },

  async mounted() {
    if (this.plan) {
      this.currency = this.plan.currency;
    }

    if (userModule.user) {
      const language = userModule.user.language;
      if (language === 'ja') {
        this.currency = 'JPY';
      }
    }

    if (this.$route.query && this.$route.query.upgraded) {
      sendEvent('upgrade', { plan: this.$route.query.upgraded });
      this.$router.push('/settings?menu=plan');
    }
  },

  // watch: {
  //   changePlanModal() {
  //     if (this.card && this.team) {
  //       if (this.changePlanModal && this.team.payment && !this.team.payment.isPaymentRegistered) {
  //         this.card.unmount();
  //         this.card.mount(this.$refs.modalCardElement);
  //       } else {
  //         this.card.unmount();
  //         this.card.mount(this.$refs.cardElement);
  //       }
  //     }
  //   },
  // },
});
