
























import Vue from 'vue';

import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';

import TaskpoolContainer from '@/container/taskpool/TaskpoolContainer.vue';

export default Vue.extend({

  name: 'MobileTaskpoolContainer',

  components: {
    TaskpoolContainer,
  },

  computed: {
    isOpen() {
      return taskpoolViewModule.isMobileDialogOpen;
    },
  },

  methods: {
    setIsOpen(isOpen: boolean) {
      taskpoolViewModule.setIsMobileDialogOpen(isOpen);
    },
  },
});
