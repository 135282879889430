




































































































import Vue from 'vue';
// conmonents
import TeamNotificationsSetting from '@/components/molecules/settings/TeamNotificationsSetting.vue';
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { teamNotificationModule } from '@/store/dataModule/notification/teamNotificationModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { ChatworkChannelResponse, SlackChannelResponse, TeamNotificationSettingResonse, TeamResponse } from 'remonade-api/lib';

export interface DataType {
  host: string;
}

export default Vue.extend({
  name: 'TeamNotificationsSettingContainer',

  data() {
    return {
      host: 'http://localhost:8080' as string,
    };
  },

  components: {
    TeamNotificationsSetting,
  },

  computed: {
    team(): TeamResponse | null {
      return settingViewModule.team;
    },
    mailSettings(): TeamNotificationSettingResonse[] | null {
      if (this.team) {
        return this.team.notificationSetting.filter((setting) => setting.integrationType === 'mail');
      }
      return null;
    },
    slackSettings(): TeamNotificationSettingResonse[] | null {
      if (this.team) {
        return this.team.notificationSetting.filter((setting) => setting.integrationType === 'slack');
      }
      return null;
    },
    chatworkSettings(): TeamNotificationSettingResonse[] | null {
      if (this.team) {
        return this.team.notificationSetting.filter((setting) => setting.integrationType === 'chatwork');
      }
      return null;
    },
    slackChannels(): SlackChannelResponse[] {
      return teamNotificationModule.slackChannels;
    },
    chatworkChannels(): ChatworkChannelResponse[] {
      return teamNotificationModule.chatworkChannels;
    },
  },

  methods: {
    openSlackUrl() {
      if (this.team) {
        teamNotificationModule.openSlackUrl({ host: this.host, teamId: this.team.teamId });
      }
    },
    async unlinkSlack() {
      commonModule.setLoader(true);
      await settingViewModule.unlinkSlack();
      commonModule.setLoader(false);
    },
    openChatworkUrl() {
      if (this.team) {
        teamNotificationModule.openChatworkUrl(this.host);
      }
    },
    async unlinkChatwork() {
      commonModule.setLoader(true);
      await settingViewModule.unlinkChatwork();
      commonModule.setLoader(false);
    },
    async updateSetting(setting: TeamNotificationSettingResonse) {
      commonModule.setLoader(true);
      await teamNotificationModule.updateSetting(setting);
      commonModule.setLoader(false);
    },
  },
  async mounted() {
    this.host = `${window.location.protocol}//${window.location.host}`;
    const code = this.$route.query.code;
    const chatwork = this.$route.query.chatwork;
    commonModule.setLoader(true);
    if (code && !chatwork) {
      await settingViewModule.linkSlack({ code, host: this.host });
    } else if (code && chatwork) { // TODO 👇CHACK👇 slackはどうなってる？
      await settingViewModule.linkChatwork({ code, host: this.host });
    }
    if (this.team && this.team.isSlackConnected) {
      settingViewModule.getSlackChannelList();
    }
    if (this.team && this.team.isChatworkConnected) {
      settingViewModule.getChatworkChannelList();
    }
    commonModule.setLoader(false);
  },
});
