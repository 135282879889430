





































































































import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import { ChatworkChannelResponse, SlackChannelResponse, TeamNotificationSettingResonse } from 'remonade-api/lib';

@Component({
  components: {
  },
})
export default class TeamNotificationsSetting extends Vue {
  @Prop({ default: null }) public setting!: TeamNotificationSettingResonse;
  @Prop({ default: 'slack' }) public type!: string;
  @Prop({ default: () => [] }) public channels!: Array<SlackChannelResponse | ChatworkChannelResponse>;

  public localSetting: TeamNotificationSettingResonse | null = null;
  public isOpen: boolean = false;
  public timePicker: boolean = false;

  @Watch('setting')
  public onSettingChange(setting: TeamNotificationSettingResonse) {
    this.localSetting = setting;
  }

  @Emit('updateSetting')
  public updateSetting() {
    return this.localSetting;
  }

  public updateLocalSetting({ key, value }) {
    if (this.localSetting) {
      switch (key) {
        case 'enabled':
          this.localSetting.isEnabled = value;
          break;
        case 'channels':
          this.localSetting.channelId = value.map((channel) => channel.channelId);
          break;
        case 'message':
          this.localSetting.message = value;
          break;
        case 'time':
          this.localSetting.scheduledAt = value;
          break;
        default:
          break;
      }
      this.updateSetting();
    }
  }

  public localChannels(ids: string[]) {
    if (!ids) { return []; }
    return ids.map((id) => {
      const find = (this.channels as Array<SlackChannelResponse | ChatworkChannelResponse>).find((c) => c.channelId === id);
      if (find) { return find; }
      return null;
    }).filter((c) => c !== null);
  }

  public get settingName() {
    if (!this.localSetting) { return ''; }
    switch (this.localSetting.type) {
      case 'reminder':
        return this.$t('loginReminder');
        break;
      case 'login':
        return this.$t('loginNotification');
        break;
      case 'weekly_report':
        return this.$t('weeklyReport');
        break;
      case 'reminder_task_notregist':
        return this.$t('taskRegistrationReminder');
        break;
      case 'reminder_task_notdone':
        return this.$t('taskCompletionReminder');
        break;
      default:
        break;
    }
  }

  public get explain() {
    if (!this.localSetting) { return ''; }
    switch (this.localSetting.type) {
      case 'reminder':
        return this.$t('loginReminderMessage');
        break;
      case 'login':
        return this.$t('loginNotificationMessage');
        break;
      case 'weekly_report':
        return this.$t('weeklyReportMessage');
        break;
      case 'reminder_task_notregist':
        return this.$t('taskRegistrationReminderMessage');
        break;
      case 'reminder_task_notdone':
        return this.$t('taskCompletionReminderMessage');
        break;
      default:
        break;
    }
  }

  public mounted() {
    this.localSetting = { ...this.setting };
  }
}
