/*
 * Copyright 2017-;;;;2017 Amazon.com,; ;I;n;c. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

<template>
  <v-img
    :src="url"
  />
</template>

<script lang="js">
import LocalDataService from '@/service/LocalDataServices';
import { getImage } from '@/api/client';

export default {
  name: 'S3Image',
  props: ['imagePath', 's3ImageConfig'],
  data() {
    return {
      url: null,
      error: '',
    };
  },
  computed: {
    user() {
      return LocalDataService.getUser();
    },
  },
  watch: {
    async imagePath(value) {
      await this.getImage();
    },
  },
  async mounted() {
    await this.getImage();
  },
  methods: {
    async getImage() {
      this.url = null;
      if (!this.imagePath) {
        return null;
      }
      if (this.imagePath.startsWith('https://') || this.imagePath.startsWith('data')) {
        this.url = this.imagePath;
      } else {
        if (this.user) {
          const url = await getImage({ path: this.imagePath, teamId: this.user.teamId });
          this.url = url.url;
        }
      }
    },
  },
};
</script>