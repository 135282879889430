export interface RemonadeLanguage {
    label: string;
    locale: string;
}

export const languages = [
    {
        label: '日本語',
        locale: 'ja',
    },
    {
        label: 'English',
        locale: 'en',
    },
];
