





















import Vue from 'vue';

import SettingContainer from '@/container/settings/SettingContainer.vue';
import SettingChannelsContainer from '@/container/settings/SettingChannelsContainer.vue';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

interface DataType {
  isDrawerOpen: boolean;
}

export default Vue.extend({
  name: 'MobileSettingsContainer',

  components: {
    SettingContainer,
    SettingChannelsContainer,
  },

  data: () => {
    return {
      isDrawerOpen: false,
    };
  },

  computed: {
    isOpen() {
      return settingViewModule.isMobileDialogOpen;
    },
  },

  methods: {
    setIsOpen(isOpen: boolean) {
      settingViewModule.setIsMobileDialogOpen(isOpen);
    },
  },
});
