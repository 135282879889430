var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"team-setting-header"},[_vm._v(_vm._s(_vm.$t('tagSetting')))]),_c('v-simple-table',{staticStyle:{"margin":".5rem 3rem .5rem 3rem"},attrs:{"fixed-header":""}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"vertical-align":"middle"}},[_c('span',[_vm._v(_vm._s(_vm.$t('tagName')))])]),_c('th'),_c('th'),_c('th',{staticStyle:{"vertical-align":"middle","display":"flex","padding":"0"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin":"auto .5rem auto auto"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.createTagModal = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('add')))])])],1)])]),_c('tbody',_vm._l((_vm.usingTags),function(t){return _c('tr',{key:t.tag},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('v-text-field',{attrs:{"value":t.tagName,"dense":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.updateTag({ tag: t, key: 'name', value: $event })}}})],1),_c('td',{staticStyle:{"vertical-align":"middle","width":"20px","padding":"0 .5rem"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":t.color ? t.color : '#85928F'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-palette")])],1)]}}],null,true)},[_c('v-color-picker',{attrs:{"show-swatches":"","hide-canvas":"","hide-inputs":"","hide-mode-switch":"","value":t.color ? t.color : '#85928F'},on:{"input":function($event){return _vm.updateTag({ tag: t, key: 'color', value: $event })}}})],1)],1),_c('td',{staticStyle:{"vertical-align":"middle","width":"20px","padding":"0 .5rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.updateTagArchive(t)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive-arrow-down-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('archive')))])])],1),_c('td',{staticStyle:{"vertical-align":"middle","width":"20px","padding":"0 .5rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.openDeleteModal(t)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)])}),0)]),_c('div',{staticClass:"team-setting-subheader",staticStyle:{"margin":"2rem 0 0 0"}},[_vm._v(_vm._s(_vm.$t('archivedTags')))]),_c('v-simple-table',{staticStyle:{"margin":"0 3rem .5rem 3rem"},attrs:{"fixed-header":""}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"vertical-align":"middle"}},[_c('span',[_vm._v(_vm._s(_vm.$t('tagName')))])]),_c('th'),_c('th')])]),_c('tbody',_vm._l((_vm.archivedTags),function(t){return _c('tr',{key:t.tag},[_c('td',{staticStyle:{"vertical-align":"middle"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","hide-details":""},model:{value:(t.tagName),callback:function ($$v) {_vm.$set(t, "tagName", $$v)},expression:"t.tagName"}})],1),_c('td',{staticStyle:{"vertical-align":"middle","width":"20px","padding":"0 .5rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.updateTagArchive(t)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-archive-arrow-up-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('activate')))])])],1),_c('td',{staticStyle:{"vertical-align":"middle","width":"20px","padding":"0 .5rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.openDeleteModal(t)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)])}),0)]),_c('v-dialog',{attrs:{"max-width":"40vw"},model:{value:(_vm.createTagModal),callback:function ($$v) {_vm.createTagModal=$$v},expression:"createTagModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('addTag')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('tagName'),"dense":"","outlined":"","hide-details":""},model:{value:(_vm.creatingTagName),callback:function ($$v) {_vm.creatingTagName=$$v},expression:"creatingTagName"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"auto 1rem auto auto"},attrs:{"depressed":""},on:{"click":function($event){_vm.createTagModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{staticStyle:{"margin":"auto auto auto 1rem"},attrs:{"color":"primary","depressed":""},on:{"click":_vm.createTag}},[_vm._v(_vm._s(_vm.$t('add')))]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"40vw"},model:{value:(_vm.deleteTagModal),callback:function ($$v) {_vm.deleteTagModal=$$v},expression:"deleteTagModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('deleteTag')))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('deleteMessageEn'))+" "+_vm._s(_vm.deletingTag? _vm.deletingTag.tagName : '')+" "+_vm._s(_vm.$t('deleteMessageJa')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin":"auto 1rem auto auto"},attrs:{"depressed":""},on:{"click":function($event){_vm.deleteTagModal = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{staticStyle:{"margin":"auto auto auto 1rem"},attrs:{"color":"danger","depressed":""},on:{"click":_vm.deleteTag}},[_vm._v(_vm._s(_vm.$t('delete')))]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }