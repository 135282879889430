









































































import Vue from 'vue';

import MobileTaskDialogContainer from '@/container/task/mobile/MobileTaskDialogContainer.vue';
import MobileTaskpoolContainer from '@/container/taskpool/mobile/MobileTaskpoolContainer.vue';
import MobileSettingsContainer from '@/container/settings/mobile/MobileSettingsContainer.vue';
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import dayjs from 'dayjs';
import { MobileMenus, mobileViewModule } from '@/store/viewModule/mobile/mobileViewModule';
import { calendarModule } from '@/store/dataModule/calendar/calendarModule';
import { pusherModule } from '@/store/dataModule/pusher/pusherModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { chatModule } from '@/store/dataModule/chat/chatModule';
import { mobileChatViewModule } from '@/store/viewModule/chat/mobileChatViewModule';

interface DataType {
  isDrawerOpen: boolean;
  navigationValue: number;
}

export default Vue.extend({
  name: 'MobileAppContainer',

  components: {
    MobileTaskDialogContainer,
    MobileTaskpoolContainer,
    MobileSettingsContainer,
  },

  computed: {
    mobileMenus() {
      return MobileMenus;
    },
    selectedMenu() {
      return mobileViewModule.selectedMenu;
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  data: (): DataType => {
    return {
      isDrawerOpen: false,
      navigationValue: 0,
    };
  },

  methods: {
    taskButtonClicked() {
      taskDialogModule.setOpen(true);
    },
    poolButtonClicked() {
      taskpoolViewModule.setIsMobileDialogOpen(true);
    },
    selectMenu(menu: string) {
      if (menu !== this.selectedMenu) {
        this.$router.push({ name: 'mobile', query: { menu } });
        mobileViewModule.selectMenu(menu);
      }
    },
    async fetchNippoData() {
      if (userModule.user && userModule.user.nippoUserId && userModule.user.teamId) {
        teamModule.findTeam(userModule.user.teamId);
        journalModule.setNippoUserId(userModule.user.nippoUserId);
        journalViewModule.getTeamNotes();
        const start = dayjs().startOf('month').format('YYYY-MM-DD');
        const end = dayjs().endOf('month').format('YYYY-MM-DD');
        await journalModule.listMyDailyNotes({
          teamId: userModule.user.teamId,
          from: start,
          to: end,
        });
      }
    },

    async fetchGoogleData() {
      if (userModule.isGoogleUser && userModule.user.isGoogleCalendarEnabled) {
        await calendarModule.getGoogleCalendars(dayjs().format('YYYY-MM-DD'));
        taskDialogModule.getConnectCalendars();
      }
    },
  },

  async created() {
    const isSessionValid = await commonModule.checkSession();

    if (!isSessionValid) {
      this.$router.push({ name: 'signin' });
    }
  },

  async mounted() {
    commonModule.setLoader(true);
    const menu = location.search.split('=')[1];
    switch (menu) {
      case 'TODAY':
        this.navigationValue = 0;
        break;
      case 'CHAT':
        this.navigationValue = 1;
        break;
      case 'JOURNAL':
        this.navigationValue = 2;
        break;
      case 'STATS':
        this.navigationValue = 3;
        break;
      default:
        this.navigationValue = 0;
        break;
    }
    await userModule.findMyUser();
    await Promise.all([
      userModule.listTeamUsers(),
      userModule.checkGoogleUser(),
      channelsModule.listChannels(),
      tagModule.listTags(),
      todayViewModule.getTodayData(),
      taskpoolViewModule.getTaskpoolData(),
      statsViewModule.getStatsData(),
      this.fetchNippoData(),
      this.fetchGoogleData(),
    ]);
    todayViewModule.getTodayData();
    mobileChatViewModule.getChatData();
    taskpoolViewModule.getTaskpoolData();
    journalViewModule.getJournalData();
    statsViewModule.getStatsData();
    if (!process.env.IS_ELECTRON) {
      pusherModule.connectPusher();
      pusherModule.initBeamsClient();
      pusherModule.addInterestOfBeams();
    }
    commonModule.setLoader(false);
  },
});
