















































import Vue from 'vue';

// components
import TaskCreateContainer from '@/container/task/TaskCreateContainer.vue';
import TodayTasksContainer from '@/container/task/TodayTasksContainer.vue';
import TaskCopyContainer from '@/container/task/TaskCopyContainer.vue';
import TaskMoveContainer from '@/container/task/TaskMoveContainer.vue';

// store
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';

// types
import { TaskItem } from '@/model/types';
import dayjs from 'dayjs';

export interface DataType {
}

export default Vue.extend({
  name: 'MobileTaskDialogContainer',

  components: {
    TaskCreateContainer,
    TodayTasksContainer,
    TaskCopyContainer,
    TaskMoveContainer,
  },

  data: (): DataType => {
    return {
    };
  },

  computed: {
    isEditable() {
      return taskDialogModule.isEditable;
    },
    isOpen() {
      return taskDialogModule.isDialogOpen;
    },
    currentDate(): string {
      return taskDialogModule.currentDate;
    },
  },

  methods: {
    setIsOpen(open: boolean) {
      taskDialogModule.setOpen(open);
    },
    pickDate(value: string) {
      taskDialogModule.setCurrentDate(value);
    },
  },

  async mounted() {
    // await taskDialogModule.getTaskDialogData();
  },
});
