













import Vue from 'vue';
// conmonents
import UserSettingContainer from '@/container/settings/UserSettingContainer.vue';
import TeamTeamSettingContainer from '@/container/settings/TeamTeamSettingContainer.vue';
import TeamPlanSettingContainer from '@/container/settings/TeamPlanSettingContainer.vue';
import TeamNotificationsSettingContainer from '@/container/settings/TeamNotificationsSettingContainer.vue';
import TeamTagSettingContainer from '@/container/settings/TeamTagSettingContainer.vue';
import TeamUsersSettingContainer from '@/container/settings/TeamUsersSettingContainer.vue';
import AlertContainer from '@/container/common/AlertContainer.vue';
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { UserResponse } from 'remonade-api/lib';

export interface DataType {
}

export default Vue.extend({
  name: 'SettingContainer',

  data() {
    return {
    };
  },

  components: {
    UserSettingContainer,
    TeamTeamSettingContainer,
    TeamPlanSettingContainer,
    TeamNotificationsSettingContainer,
    TeamTagSettingContainer,
    TeamUsersSettingContainer,
    AlertContainer,
  },

  computed: {
    selectedChannel(): string {
      return settingViewModule.selectedChannel;
    },
    user(): UserResponse | null {
      return settingViewModule.user;
    },
    isAdmin(): boolean {
      if (this.user && this.user.userType === 'admin') {
        return true;
      }
      return false;
    },
  },

  methods: {
  },

  async mounted() {
    commonModule.setLoader(true);
    await settingViewModule.getSettingData();
    commonModule.setLoader(false);
  },
});
